//--------------BAZAR--------------
import art_106 from "../assets/images/articulos/bazar/106.jpg";
import art_205 from "../assets/images/articulos/bazar/205.jpg";
import art_210_215 from "../assets/images/articulos/bazar/210-215.jpg";
import art_220 from "../assets/images/articulos/bazar/220.jpg";
import art_230 from "../assets/images/articulos/bazar/230.jpg";
import art_235 from "../assets/images/articulos/bazar/235.jpg";
import art_240 from "../assets/images/articulos/bazar/240.jpg";
import art_250 from "../assets/images/articulos/bazar/250.jpg";
import art_275_285 from "../assets/images/articulos/bazar/275-285.jpg";
import art_310 from "../assets/images/articulos/bazar/310.jpg";
import art_430 from "../assets/images/articulos/bazar/430.jpg";
import art_450 from "../assets/images/articulos/bazar/450.jpg";
import art_580 from "../assets/images/articulos/bazar/580.jpg";
import art_615 from "../assets/images/articulos/bazar/615.jpg";
import art_640_645 from "../assets/images/articulos/bazar/640-645.jpg";
import art_650_660_670_680 from "../assets/images/articulos/bazar/650-660-670-680.jpg";
import art_685 from "../assets/images/articulos/bazar/685.jpg";
import art_700_703 from "../assets/images/articulos/bazar/700-703.jpg";
import art_701_705_710_715 from "../assets/images/articulos/bazar/701-705-710-715.jpg";
import art_720 from "../assets/images/articulos/bazar/720.jpg";

//--------------INFANTIL--------------
import art_260_330 from "../assets/images/articulos/infantil/260-330.jpg";
import art_290_320 from "../assets/images/articulos/infantil/290-320.jpg";
import art_300_340 from "../assets/images/articulos/infantil/300-340.jpg";
import art_350 from "../assets/images/articulos/infantil/350.jpg";
import art_360 from "../assets/images/articulos/infantil/360.jpg";
import art_370 from "../assets/images/articulos/infantil/370.jpg";
import art_365_375 from "../assets/images/articulos/infantil/365-375.jpg";
import art_380 from "../assets/images/articulos/infantil/380.jpg";
import art_390_400 from "../assets/images/articulos/infantil/390-400.jpg";
import art_385_406 from "../assets/images/articulos/infantil/385-406.jpg";
import art_410 from "../assets/images/articulos/infantil/410.jpg";
import art_620 from "../assets/images/articulos/infantil/620.jpg";
import art_630 from "../assets/images/articulos/infantil/630.jpg";
import art_635 from "../assets/images/articulos/infantil/635.jpg";

const articulos = {
  bazar: [
    { codigo: "106", descripcion: "RALLADOR + COLAPASTA", img: art_106, visible: true, order: 1 },
    { codigo: "235", descripcion: "ESCURRIDOR DE CUBIERTOS", img: art_235, visible: true, order: 1 },
    { codigo: "205", descripcion: "RALLADOR CON PLATO", img: art_205, visible: true, order: 1 },
    { codigo: "230", descripcion: "COLAPASTAS", img: art_230, visible: true, order: 1 },
    { codigo: "310", descripcion: "MATE TERMICO DOBLE ASA", img: art_310, visible: true, order: 1 },
    { codigo: "220", descripcion: "PLATO DE TRES SECCIONES", img: art_220, visible: true, order: 1 },
    { codigo: "430", descripcion: " VIANDA MATERA MULTIPLE", img: art_430, visible: true, order: 1 },
    {
      codigo: "700-703",
      descripcion: "700 - JARRA CRISTAL CON MEZCLADORA\n703 - JUEGO 4 VASOS FACETADOS CRISTAL",
      img: art_700_703,
      visible: true,
      order: 1,
      relacionado: true,
    },
    {
      codigo: "640-645",
      descripcion: "640 - BANDEJA FACETADA CRISTAL\n645 - CAMPANA CRISTAL CON BANDEJA",
      img: art_640_645,
      visible: true,
      order: 1,
    },
    {
      codigo: "701-705-710-715",
      descripcion:
        "701 - JUEGO 4 VASOS FACETADOS COLOR\n705 - PLATO PLAYO DIAMETRO 22 CM\n710 - PLATO HONDO DIAMETRO 22 CM\n715 - PLATO POSTRE DIAMETRO 18 CM",
      img: art_701_705_710_715,
      visible: true,
      order: 1,
      relacionado: true,
    },
    { codigo: "240", descripcion: "MOLDE PARA HAMBURGUESAS", img: art_240, visible: true, order: 1 },
    { codigo: "615", descripcion: "CAMPANA MEDIANA C/BOTON", img: art_615, visible: true, order: 1, destacado: true },
    {
      codigo: "580",
      descripcion: "QUESERA Y/O AZUCARERA linea 2000",
      img: art_580,
      visible: true,
      order: 1,
      relacionado: true,
    },
    { codigo: "685", descripcion: "TARRO TRIANGULAR MULTIUSO", img: art_685, visible: true, order: 1 },
    { codigo: "720", descripcion: "ENSALADERA DE CRISTAL FACETADA", img: art_720, visible: true, order: 1 },
    { codigo: "450", descripcion: " HIELERA BOTELLA COGNAC", img: art_450, visible: true, order: 1 },
    {
      codigo: "275-285",
      descripcion: "275 - CONJUNTO MATERO CON MATE\n285 - SET AZUCARERA Y YERBERA",
      img: art_275_285,
      visible: true,
      order: 1,
    },
    { codigo: "250", descripcion: "MOLDE PARA RAVIOLES + PALITA PARA ÑOQUIS", img: art_250, visible: true, order: 1 },
    {
      codigo: "210-215",
      descripcion: "210 - EXPRIMIDOR CON JARRA MEDIDORA\n215 - JARRA MEDIDORA Y VERTEDORA",
      img: art_210_215,
      visible: true,
      order: 1,
    },
    {
      codigo: "650-660-670-680",
      descripcion:
        "650 - TARRO TRANSPARENTE N°1\n660 - TARRO TRANSPARENTE N°2\nArt. 670 - TARRO TRANSPARENTE N°3\nArt. 680 - JUEGO 3 TARROS TRANSPARENTES CON TAPA HERMETICA",
      img: art_650_660_670_680,
      width: 230,
      visible: true,
      order: 1,
    },
  ],
  infantil: [
    {
      codigo: "260-330",
      descripcion: "Art. 260 - VASO SORBITO REVOLVER \n Art. 330 - VASO SORBITO REVOLVER CON SOMBRERO",
      img: art_260_330,
      visible: true,
      order: 1,
      destacado: true,
    },
    {
      codigo: "290-320",
      descripcion: "Art. 290 - VASO SORBITO BOTA \n Art. 320 - VASO SORBITO BOTA CON SOMBRERO",
      img: art_290_320,
      visible: true,
      order: 1,
      relacionado: true,
    },
    {
      codigo: "300-340",
      descripcion: "Art. 340 - VASO SORBITO CUCURUCHO \n Art. 300 - VASO INFANTIL VARIOS COLORES",
      img: art_300_340,
      visible: true,
      order: 1,
      destacado: true,
      relacionado: true,
    },
    { codigo: "350", descripcion: "VASO SORBITO BEBE DOBLE ASA", img: art_350, visible: true, order: 1 },
    { codigo: "360", descripcion: "VASO SORBITO MAGICO", img: art_360, visible: true, order: 1 },
    { codigo: "370", descripcion: "VASO INFANTIL COCORITO", img: art_370, visible: true, order: 1, relacionado: true },
    {
      codigo: "365-375",
      descripcion: "Art. 365 - PLATO CON SORBITO \n Art. 375 - PLATO CON SORBITO + VASO BEBE",
      img: art_365_375,
      visible: true,
      order: 1,
    },
    { codigo: "380", descripcion: "", img: art_380, visible: false, order: 1 },
    {
      codigo: "390-400",
      descripcion: "Art. 390 - JUEGO TAZA Y PLATO DESAYUNO \n Art. - 400 TAZA SOLA PARA DESAYUNO",
      img: art_390_400,
      visible: true,
      order: 1,
    },
    {
      codigo: "385-406",
      descripcion: "Art. 406 - SET INFANTIL CON CUCHARITA AVION \n Art. 385 - CUCHARITA INFANTIL AVION",
      img: art_385_406,
      visible: true,
      order: 1,
    },
    { codigo: "410", descripcion: "LUNCHERA INFANTIL CON CUBIERTOS", img: art_410, width:165, visible: true, order: 1 },
    { codigo: "620", descripcion: "BACINILLA", img: art_620, visible: true, order: 1 },
    { codigo: "630", descripcion: "REDUCTOR TAPA INODORO INFANTIL", img: art_630, visible: true, order: 1 },
    { codigo: "635", descripcion: "BACINILLA ANATOMICA", img: art_635, visible: true, order: 1 },
  ],
};

function listArticulos(linea) {
  return articulos[linea].filter(a => a.visible === true).sort((a, b) => a.order - b.order);
}

function getArticulo(linea, codigo) {
  return articulos[linea].find(a => a.codigo === codigo);
}

function getDestacados() {
  return articulos["bazar"]
    .filter(a => a.destacado === true)
    .concat(articulos["infantil"].filter(a => a.destacado === true));
}

function getRelacionados(linea) {
  return articulos[linea].filter(a => a.relacionado === true);
}

export { listArticulos, getArticulo, getDestacados, getRelacionados };
